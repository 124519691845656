<template>
  <div>
    <!--loading-->
    <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !isLoading }"/>

    <div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <ValidationErrors :errors="errors"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">No. Kontrak</label>
          <vs-input class="w-full" v-model="data.no_kontrak" disabled/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Tgl. Kontrak</label>
          <flat-pickr class="w-full" v-model="data.tgl"></flat-pickr>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Masa Garansi (hari)</label>
          <vs-input class="w-full" v-model="data.masa_garansi"/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Durasi Penyerahan (bulan)</label>
          <vs-input class="w-full" v-model="data.durasi_penyerahan"/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/2 w-full">
          <label class="ml-1 text-xs">Staf</label>
          <vs-input class="w-full" v-model="data.nama_staf" disabled/>
        </div>
        <div class="vx-col sm:w-1/2 w-full">
          <label class="ml-1 text-xs">Koordinator</label>
          <vs-input class="w-full" v-model="data.nama_staf_atasan" disabled/>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/1 w-full">
          <label class="ml-1 text-xs">Keterangan Kontrak</label>
          <vs-textarea class="w-full" v-model="data.keterangan_kontrak"/>
        </div>
      </div>

      <div class="vx-row float-right mt-6">
        <div class="vx-col w-full">
          <div class="flex items-center space-x-3">
            <p v-if="!!data.id_jurnal">Data yang sudah terposting tidak dapat diubah.</p>
            <vs-button :disabled="isLoading || !!data.id_jurnal" color="primary" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KontrakRepository from '@/repositories/marketing/kontrak/kontrak-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'TabKontrak',
  props: ['isActive'],
  components: {
    flatPickr,
    ValidationErrors
  },
  watch: {
    isActive (value) {
      if (value && !this.isDataInited) {
        this.initData()
      }
    }
  },
  data () {
    return {
      isDataInited: false,
      isLoading: false,
      errors: null,
      data: {}
    }
  },
  methods: {
    initData () {
      this.getKontrak()
      this.isDataInited = true
    },

    getKontrak () {
      this.isLoading = true

      const idKontrak = this.$route.params.idKontrak
      KontrakRepository.show(idKontrak)
        .then(response => {
          this.data = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = {
        id_kontrak: this.data.id,
        tgl: this.data.tgl,
        masa_garansi: this.data.masa_garansi,
        durasi_penyerahan: this.data.durasi_penyerahan,
        keterangan_kontrak: this.data.keterangan_kontrak
      }
      KontrakRepository.update(params)
        .then(response => {
          this.notifySuccess('Data berhasil disimpan.')
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
            this.notifyError('Data tidak valid.')
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
